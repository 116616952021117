import React from 'react';
import getNextImageUrl from 'helpers/getNextImageUrl';
import useSiteLink from 'hooks/useSiteLink';
import Head from 'next/head';
import { useRouter } from 'next/router';

const Seo = ({ data = {}, postfix }) => {
  const { asPath } = useRouter();
  const { title, description, image } = data;
  const url = useSiteLink(asPath);
  const isPreview = asPath.startsWith('/preview');

  const seoTitle = `${isPreview ? `[預覽] ` : ''}${title} | ${
    postfix ?? process.env.NEXT_PUBLIC_APP_NAME
  }`;

  return (
    <Head>
      {title && <title>{seoTitle}</title>}
      {description && <meta name="description" content={description} />}
      <meta property="og:url" content={url} />
      {title && <meta property="og:title" content={seoTitle} />}
      {description && <meta property="og:description" content={description} />}
      {image && (
        <meta property="og:image" content={getNextImageUrl(image, 1200, 100)} />
      )}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      {title && <meta property="twitter:title" content={seoTitle} />}
      {description && (
        <meta property="twitter:description" content={description} />
      )}
      {image && <meta property="twitter:image" content={image} />}
    </Head>
  );
};

export default Seo;
