import { Box, Flex, useMergeRefs } from '@chakra-ui/react';
import React, { useMemo, useRef } from 'react';
import { useMeasure } from 'react-use';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const LoopSlider = ({
  items,
  itemWidth,
  spaceBetween,
  duration = 5000,
  reverseDirection,
  renderer,
  // priority,
  ...props
}) => {
  const [measureRef, { width }] = useMeasure();
  const spared = useMemo(
    () => Math.floor(width / (itemWidth * spaceBetween * 2 + itemWidth)) + 2,
    [width, spaceBetween, itemWidth]
  );
  const ref = useRef();

  const avatarList = useMemo(() => {
    const toAdd = [];
    let i = 0;
    while (toAdd.length < spared) {
      if (i >= items.length) {
        i = 0;
      }
      toAdd.push(items[i]);
      i++;
    }
    let newList = [...items, ...toAdd];
    if (reverseDirection) {
      newList = newList.reverse();
    }
    return newList;
  }, [items, spared, reverseDirection]);

  const StyledSlider = useMemo(() => {
    if (!width) return Box;
    const sliderKeyframe = keyframes(`
    100% {
      transform: translateX(${
        (reverseDirection ? 1 : -1) * (100 / (1 + spared / items.length))
      }%);
    }
    `);
    return styled(Box)`
      animation: ${sliderKeyframe} ${(duration * items.length) / 1000}s linear
        infinite;
    `;
  }, [items.length, duration, reverseDirection, spared]);

  return (
    <Flex
      justifyContent={reverseDirection ? 'flex-end' : 'flex-start'}
      ref={useMergeRefs(ref, measureRef)}
      {...props}
    >
      <StyledSlider>
        <Box width="max-content">
          {avatarList.map((item, index) => (
            <Box
              key={index}
              w={`${itemWidth}px`}
              mx={`${itemWidth * spaceBetween}px`}
              display="inline-block"
            >
              {renderer(item, index, Boolean(width))}
            </Box>
          ))}
        </Box>
      </StyledSlider>
    </Flex>
  );
};

export default LoopSlider;
