import merge from 'lodash/merge'
const fetcher = (...args) => fetch(...args).then(res => res.json())

fetcher.post = (url, config = {}) => fetcher(url, merge({}, config, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
}))

export default fetcher
