import logo1 from './logo 01-五口.png';
import logo2 from './logo 02-親子天下小行星.png';
import logo3 from './logo 03-萊爾富.png';
import logo4 from './logo 04-台灣角川.png';
import logo5 from './logo 05-VIIGO.png';
import logo6 from './logo 06-兩廳院.png';
import logo7 from './logo 07-SEAGATE.png';
import logo8 from './logo 08-T1.png';
import logo9 from './logo 09-只要有人.png';
import logo10 from './logo 10-上上芊.png';
import logo11 from './logo 11-法律白話文運動.png';
import logo12 from './logo 12-Giloo.png';
import logo13 from './logo 13-上上芊.png';
export const logoEven = [logo1,logo3,logo5,logo7,logo9,logo11,logo13];
export const logoOdd = [logo2,logo4,logo6,logo8,logo10,logo12];