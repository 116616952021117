import React from 'react';
import { Box, Flex, Stack, useBreakpointValue } from '@chakra-ui/react';
import Image from 'next/image';
import { Media, responsive } from 'contexts/responsive';
import TitleText from 'components/TitleText';
import LoopSlider from 'components/LoopSlider';
import brandDeco from './brand-deco.svg';
import brandDecoMobile from './brand-deco-m.svg';
import logoBase from './logo.svg';
import RatioImage from 'components/RatioImage';

const size = {
  base: 16,
  md: 24,
  xl: 32
};

import { logoEven, logoOdd } from './logo';

const BrandLogo = ({ src }) => (
  <Box pos="relative" zIndex={10}>
    <RatioImage ratio={1} src={logoBase}></RatioImage>
    <RatioImage
      pos="absolute"
      top="15%"
      left="15%"
      bottom="15%"
      right="15%"
      ratio={1}
      src={src}
    ></RatioImage>
  </Box>
);

const Brands = () => {
  const logoWidth = useBreakpointValue({
    base: 108,
    md: 174
  });
  const spacing = useBreakpointValue({
    base: 0.1,
    md: 0.2
  });
  const sliderCfg = {
    duration: 5000,
    itemWidth: logoWidth,
    spaceBetween: spacing,
    renderer: (src) => <BrandLogo src={src} />
  };
  return (
    <Stack
      py={responsive('5.375em', '5.125em')}
      spacing={{ base: 4, md: 12 }}
      pos="relative"
      zIndex={5}
    >
      <Flex
        mb={{ base: 4, lg: 12 }}
        justifyContent="center"
        alignItems="center"
      >
        <Box flexGrow="0" flexShrink="0" flexBasis={size} />
        <TitleText
          mx={responsive('0', '1rem')}
          letterSpacing={responsive('0.027em', '3.6px')}
        >
          哪些品牌用過
          <Media lessThan="md" as="br" />
          超開放實驗室？
        </TitleText>
        <Box flexGrow="0" flexShrink="0" flexBasis={size}>
          <Media greaterThanOrEqual="md" transform="translateY(-25%)">
            <Image src={brandDeco} alt="deco" />
          </Media>
          <Media lessThan="md" transform="translate(-25%, -66%)">
            <Image src={brandDecoMobile} alt="deco" />
          </Media>
        </Box>
      </Flex>
      <Stack spacing={{ base: 4, md: 8 }}>
        <LoopSlider {...sliderCfg} items={logoEven} />
        <LoopSlider {...sliderCfg} items={logoOdd} reverseDirection />
      </Stack>
      <Box h="5vh" />
    </Stack>
  );
};

export default Brands;
