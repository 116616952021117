import React, {
  createElement,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { DESCRIPTION as META_DESCRIPTION } from 'constants/site';
import { useScrolling } from 'react-use';
// import playBgMobile from 'containers/images/play_bg_mobile.png';
// import playBg from 'containers/images/play_bg.png';
import geometry from 'containers/images/geometry.svg';
// import big_geometry from 'containers/images/big_geometry.svg';
import plan from 'containers/images/bg_plan.svg';
// import plan2 from 'containers/images/bg_plan-2.svg';
// import plan3 from 'containers/images/bg_plan-3.svg';
import plan_mobile from 'containers/images/bg_plan-m.svg';
import btn_start from 'containers/images/btn_start.png';
// import plan_mobile2 from 'containers/images/bg_plan-2-m.svg';
// import plan_mobile3 from 'containers/images/bg_plan-3-m.svg';
import HeadMeta from 'components/HeadMeta';
import { Box, Portal, keyframes, useBreakpointValue } from '@chakra-ui/react';
// import ReactFullpage from '@fullpage/react-fullpage';
import findIndex from 'lodash/findIndex';
// import { useLocation } from 'react-use';
import OpenLabHeader from '../containers/OpenLabHeader';
import useSiteLink from 'hooks/useSiteLink';
import CoverPage from '../containers/CoverPage';
import Communication from '../containers/Communication';
import Faq from '../containers/Faq';
// import Operate from '../containers/Operate';
import Lab from '../containers/Lab';
// import Example from '../containers/Example';
import GamePage from '../containers/GamePage';
import Motivation from 'containers/Motivation';
import Play from '../containers/Play';
import Brands from '../containers/Brands';
import CreativeMobile from '../containers/CreativeMobile';
import Contact from '../containers/Contact';
import About from '../containers/About';
// import Try from '../containers/Try';
import Reasons from '../containers/Reasons';
import FeeSection from 'containers/FeeSection';
import { responsive } from 'contexts/responsive';
import RatioImage from 'components/RatioImage';
import styled from '@emotion/styled';
import Link from 'next/link';
// import FooterHome from 'components/footer/Home';

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(24px);
  }
  60% {
    transform: translateX(9px);
  }
`;
const Bounced = styled(Box)`
  animation: ${bounce} 2s infinite;
`;

const anchors = [
  'cover',
  'communication',
  'reasons',
  'lab',
  // 'example',
  'game',
  'play',
  'plans',
  'feeAdvanced',
  'compare',
  'motivation',
  'help',
  'about'
];

const comp = [
  { ele: CoverPage, key: 'cover', bgImg: '' },
  { ele: Communication, key: 'communication', bgImg: '' },
  { ele: Reasons, key: 'reasons', bgImg: '' },
  { ele: Lab, key: 'lab', bgImg: '' },
  { ele: GamePage, key: 'game', bgImg: '', bgColor: '#78D4B0', bgPos: 'top' },
  { ele: Play, key: 'play', bgImg: '', bgColor: '#78D4B0' },
  { ele: Brands, key: 'brands', bgImg: '', bgColor: '#78D4B0' },
  {
    ele: FeeSection,
    key: 'plans',
    bgImg: plan,
    mBgImg: plan_mobile,
    bgColor: '#FDC65A'
  },
  // { ele: FeeAdvanced, key: 'feeAdvanced', bgImg: plan2, mBgImg: plan_mobile2, bgColor: '#FDC65A'},
  // { ele: Compare, key: 'compare', bgImg: plan3, mBgImg: plan_mobile3, bgColor: '#FDC65A'},
  { ele: Motivation, key: 'motivation', bgImg: '' },
  // { ele: Faq, key: 'faq', bgImg: '' },
  { ele: Contact, key: 'contact', bgImg: '' },
  { ele: About, key: 'about', bgImg: '', bgColor: '#FFF' }
];

const compmobile = [...comp];
compmobile.splice(compmobile.findIndex((d) => d.key === 'help') + 1, 0, {
  ele: CreativeMobile,
  key: 'creative',
  bgImg: ''
});

const anchorsMobile = [...anchors];
anchorsMobile.splice(
  anchorsMobile.findIndex((d) => d === 'help') + 1,
  0,
  'creative'
);

let ele;

const Home = () => {
  // const tryRef = useRef();
  const ref = useRef();
  const [scrollPos, setScrollPos] = useState();
  const [scrolling, setScroll] = useState();
  useEffect(() => {
    if (document && window) {
      ele = document.getElementById('cover');
      const onScrollStop = (callback) => {
        let isScrolling;
        window.addEventListener(
          'scroll',
          (e) => {
            setScroll(true);
            setScrollPos(window.scrollY);
            clearTimeout(isScrolling);
            isScrolling = setTimeout(() => {
              callback();
            }, 500);
          },
          false
        );
      };
      onScrollStop(() => {
        setScroll(false);
      });
    }
  }, []);

  const showBtn = useMemo(() => scrollPos > ele?.clientHeight / 2, [scrollPos]);

  return (
    <>
      <HeadMeta
        url={useSiteLink('/')}
        title={process.env.NEXT_PUBLIC_APP_NAME}
        description={META_DESCRIPTION}
        ogImage={useSiteLink('/ogImage.png')}
      />
      <OpenLabHeader />
      <Box ref={ref} overflowX="hidden">
        {comp.map((data) => (
          <Box
            id={data.key}
            bg={data.bgColor || '#FCF6ED'}
            pos="relative"
            backgroundImage={responsive(
              `url(${data?.mBgImg?.src})`,
              `url(${data?.bgImg?.src})`
            )}
            backgroundSize="100%"
            backgroundPosition={data.bgPos || 'center'}
            backgroundRepeat="no-repeat"
            key={data.key}
            // zIndex={length - index}
          >
            {createElement(data.ele, {})}
          </Box>
        ))}
      </Box>

      <aside>
        <Box
          as={Link}
          href={'/editor'}
          right={responsive('1em', '1.5em')}
          bottom={responsive('1em', '1.5em')}
          width={responsive('4.375em', '6.25em')}
          transform={showBtn && !scrolling ? 'scale(1)' : 'scale(0)'}
          transition={'all 0.1s'}
          pos="fixed"
          zIndex={999}
        >
          <Bounced>
            <RatioImage src={btn_start} alt="Start" />
          </Bounced>
        </Box>
      </aside>
    </>
  );
};

export default Home;
