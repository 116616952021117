import React from 'react';
import { Box } from '@chakra-ui/react';
import Link from 'next/link';
// import SVG from 'components/SVG';
import Carousel from 'components/Carousel';
import RatioImage from 'components/RatioImage';
import { Media } from '../contexts/responsive';
import plans from 'containers/images/plans.webp';
import plansWide from 'containers/images/plansWide.webp';
import use2Hours from 'containers/images/2hours.png';
import use2HoursWide from 'containers/images/2hoursWide.png';
import happy2You from 'containers/images/happy2You.webp';
import happy2YouWide from 'containers/images/happy2YouWide.webp';

const banners = [
  {
    bg: use2Hours,
    bgWide: use2HoursWide,
    bgColor: '#FCF6ED',
    alt: '2 小時製作測驗，讓品牌溝通變好玩'
  },
  {
    bg: plans,
    bgWide: plansWide,
    // bgColor: '#FCF6ED',
    alt: '線上付款正式啟用 查看完整方案說明',
    href: '/plans'
  },
  // {
  //   bg: happy2You,
  //   bgWide: happy2YouWide,
  //   bgColor: '#AA0800',
  //   alt: '兔兔賀卡產生器',
  //   href: 'https://url.relab.cc/230119_ooopenlab_2023rabbit'
  // }
];

// let dom

const CoverPage = () => {
  // const messages = {
  //   slogan: [
  //     ['帶給你的受眾', '全新的溝通體驗'],
  //     ['把品牌想傳達的內容', '用好玩的方式說']
  //   ],
  //   cta: '遊戲模組介紹'
  // };
  // const [active, setActive] = useState(0)
  // useEffect(() => {
  //   if (document) {
  //     dom = document.getElementById("game")
  //   }
  // } ,[])
  return (
    <Box pos="relative" overflow="hidden" transition="all 760ms">
      <Carousel
        // setActive={setActive}
        // active={active}
        trackerType={'banner_click'}
        trackerName={'banner'}
        arrows={true}
        autoplay
        sliderProps={{
          infinite: true,
          autoplaySpeed: 4000
        }}
        static
        dotProps={{
          bottom: '1em',
          transform: 'translate(-50%, -100%)'
        }}
      >
        {banners.map(({ bg, bgWide, href, alt }, key) => (
          <Box
            as={href && Link}
            href={href}
            target="_blank"
            key={key}
            onClick={() => {
              if (typeof window.gtag === 'function') {
                window.gtag('event', 'banner_click', {
                  button_name: `banner${key + 1}`
                });
              }
            }}
          >
            <Media lessThan={'md'}>
              <RatioImage src={bg} ratio={1125 / 2000} alt={alt} />
            </Media>
            <Media greaterThanOrEqual={'md'}>
              <RatioImage src={bgWide} ratio={2400 / 1300} alt={alt} />
            </Media>
          </Box>
        ))}
      </Carousel>
      {/* <Flex
        pos="absolute"
        maxWidth={['30em', 'unset']}
        width={['115%', '67.5%', null, null, '72.5%']}
        top={{
          base: '50px',
          md: '76px',
          xl: '92px'
        }}
        bottom="0"
        left={responsive('50%', 'auto')}
        right={responsive('auto', '7.5%', null, null, '4.375%')}
        transform={responsive('translateX(-50%)', 'none')}
        flexDir="column"
        justifyContent="center"
      >
        <Media greaterThanOrEqual="md">
          <svg viewBox="0 0 1553.22 926.32" alt="Open Lab">
            <use xlinkHref="/sprites/home.svg#coverOpenlabWide" />
          </svg>
        </Media>
        <Media lessThan="md">
          <svg viewBox="0 0 1477.62 1627.95" alt="Open Lab">
            <use xlinkHref="/sprites/home.svg#coverOpenlab" />
          </svg>
        </Media>
      </Flex>
      <Container
        maxW="container.xl"
        pos="relative"
        mx={responsive(0, 0, '5em', null, '8.5em')}
        px={responsive(3, '3em', 0)}
        height="100%"
      >
        <Center
          alignItems={responsive('flex-end', 'center')}
          pb={responsive(12, 0)}
          pt={responsive(0, '2em', null, '4em')}
          height="100%"
        >
          <Box flex="0 0 100%">
            <Stack
              as="h1"
              spacing={responsive(2, 3, 5)}
              marginBlockEnd={6}
              alignItems={'flex-start'}
            >
              {messages.slogan.map((sentence, index) => (
                <Stack
                  as="span"
                  spacing={responsive(0, 3, 5)}
                  color="white"
                  flexDirection={responsive('row', 'column')}
                  alignItems={'flex-start'}
                  key={`slogan${index}`}
                >
                  {sentence.map((text) => (
                    <Box
                      as="span"
                      lineHeight={1}
                      letterSpacing="0.25rem"
                      py={responsive('0.5rem', '0.625rem')}
                      px={responsive('0.375rem', '0.75rem')}
                      bg="black"
                      fontSize={responsive(
                        '1em',
                        '1.25em',
                        null,
                        '1.5em',
                        '2.125em'
                      )}
                      key={text}
                    >
                      {text}
                    </Box>
                  ))}
                </Stack>
              ))}
            </Stack>
            <Box textAlign={['center', 'initial']}>
              <Button
                variant="outline"
                size="lg"
                rightIcon={
                  <SVG w={6} h={6} viewBox="0 0 50 50" alt="">
                    <use
                      xlinkHref="/sprites/home.svg#mediumArrow"
                      transform="rotate(90, 25, 25)"
                    />
                  </SVG>
                }
                _hover={{
                  color: 'white',
                  backgroundColor: 'black'
                }}
                onClick={() => fullpageApi.moveTo(6)}
              >
                {messages.cta}
              </Button>
            </Box>
          </Box>
        </Center>
      </Container> */}
    </Box>
  );
};

export default CoverPage;
