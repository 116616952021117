import React from 'react';
import {
  AspectRatio,
  Box,
  Center,
  Flex,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Stack,
  Text
} from '@chakra-ui/react';

import RatioImage from '../components/RatioImage';
import Link from '../components/Link';

import block from './images/block.png';
import block_mobile from './images/block_mobile.png';
import psychology from './images/classic.svg';
import score from './images/swiper.svg';
import run from './images/runway.svg';
import survey from './images/survey.svg';
import canvas from './images/canvas.svg';
import multifactor from './images/multifactor.svg';
import measure from './images/measure.svg';
import bg from './images/play-bg.svg';
import bgMobile from './images/play-bg-m.svg';
// import shake from './images/shakit.svg';
// import soon from './soon.svg';
// import more from './more.svg';
import { Media, responsive } from 'contexts/responsive';
import TitleText from 'components/TitleText';
import Button from 'components/Button';

const games = [
  {
    text: '心理測驗',
    trackerName: 'module_classic',
    href: `/modules/classic`,
    image: psychology
  },
  {
    text: '得分挑戰',
    trackerName: 'module_swiper',
    href: `/modules/swiper`,
    image: score
  },
  {
    text: '問卷調查',
    trackerName: 'module_survey',
    href: `/modules/survey`,
    image: survey
  },
  // {
  //   text: '跑道閃避',
  //   trackerName: 'module_runway',
  //   href: `/modules/runway`,
  //   image: run
  // },
  {
    text: '客製圖產生器',
    trackerName: 'module_canvas',
    href: `/modules/canvas`,
    image: canvas
  },
  {
    text: '多維度測驗',
    trackerName: 'module_multifactor',
    href: `/modules/multifactor`,
    image: multifactor
  },
  {
    text: '計分測驗',
    href: `/modules/measure`,
    image: measure
  }
  // {
  //   text: '多維度測驗',
  //   href: `/modules/multifactor`,
  //   image: multifactor
  // },
];

const width = 228;

const Card = ({ text, href, image, comingSoon, length }) => {
  const content = (
    <Text letterSpacing={['0.25rem', null, null, '0.5rem']} fontWeight="bold">
      {text}
    </Text>
  );
  const element = (
    <Box
      as={LinkBox}
      pos="relative"
      mb={[null, '2rem', null, 0]}
      bg="black"
      transition="transform 0.2s ease-in-out"
      _hover={{
        transform: responsive('scale(1.05)', 'scale(1.1)')
      }}
    >
      <AspectRatio ratio={1} border="1px solid black">
        <Box>
          {comingSoon ? content : <LinkOverlay as={Link} href={href} />}
        </Box>
      </AspectRatio>
      <Box
        pos="absolute"
        top="-2%"
        right="-2%"
        bottom="-2%"
        left="-2%"
        pointerEvents={!comingSoon && 'none'}
      >
        <RatioImage src={image} ratio={1} alt={text} />
        <Box
          pos="absolute"
          bg="black"
          color="white"
          textAlign={'center'}
          fontSize={responsive('0.875em', '1.5em')}
          fontWeight="bold"
          letterSpacing="2px"
          lineHeight={2}
          left={0}
          right={0}
          bottom={0}
        >
          {/* <RatioImage
            src={comingSoon ? soon : more}
            ratio={526 / 99}
            alt={comingSoon ? 'Coming Soon' : 'Read More'}
          /> */}
          {text}
        </Box>
      </Box>
    </Box>
  );
  return (
    <Box
      width={'100%'}
      px={responsive('0.5em', '1.125em')}
      mt={responsive('1em', '2.25em')}
    >
      {element}
    </Box>
  );
};

const GamePage = () => {
  return (
    <>
      <Box
        px={responsive('1em', '1.5em')}
        pt={responsive('4.5em', '4.25em')}
        pb={responsive('1em', '4.25em')}
        pos="relative"
        zIndex={5}
      >
        <Box
          as={Media}
          greaterThanOrEqual="md"
          pos="absolute"
          top="0"
          bottom={0}
          left="0"
          right="0"
          zIndex={0}
        >
          <RatioImage src={bg} ratio={1280 / 1854} alt="bg" />
        </Box>
        <Box
          as={Media}
          lessThan="md"
          pos="absolute"
          top="73px"
          bottom={0}
          left="0"
          right="0"
          zIndex={0}
        >
          <RatioImage src={bgMobile} ratio={375 / 1458} alt="bg" />
        </Box>
        <Stack
          pos="relative"
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={responsive('column', 'row')}
          spacing={responsive(2, 0)}
        >
          <TitleText
            textAlign="center"
            whiteSpace={responsive('pre-wrap', 'nowrap')}
          >
            這裡提供了 <TitleText.Num>{games.length} </TitleText.Num>{' '}
            {'款\n讓溝通變好玩的模組'}
          </TitleText>
          <Box
            as={Media}
            greaterThanOrEqual="md"
            width={{
              base: '7.5em',
              xl: '8.25em'
            }}
            pl="1em"
          >
            <RatioImage src={block} ratio={393 / 282} alt="deco" />
          </Box>
          <Box
            as={Media}
            lessThan="md"
            width="5em"
            pos="absolute"
            top="0"
            right="-0.5em"
            transform={'translate(0%, -15%)'}
          >
            <RatioImage src={block_mobile} ratio={285 / 274} alt="deco" />
          </Box>
        </Stack>
        <Box
          maxW={{
            base: '720px',
            lg: '900px',
            xl: '1080px',
            '2xl': '1200px',
            '3xl': '1440px'
          }}
          width="100%"
          mx="auto"
        >
          <Center>
            <Flex
              justifyContent="center"
              flexWrap="wrap"
              mt={responsive('1em', '0.75em')}
              w={responsive('100%', Math.ceil(games.length / 2) * width)}
            >
              {games.map((d, i, { length }) => (
                <Button
                  width={['calc(100% / 3)', null, null, `${width}px`]}
                  variant="pure"
                  trackerName={d.trackerName}
                  trackerType="module_click"
                  key={i}
                >
                  <Card {...d} length={length} />
                </Button>
              ))}
            </Flex>
          </Center>
          <Box
            pos="relative"
            fontWeight="bold"
            fontSize={{
              base: '1.25em',
              lg: '1.75em',
              '2xl': '2em'
            }}
            mt={responsive('1rem', '2em')}
            textAlign={responsive('center', 'right')}
            px="2"
          >
            持續更新中...
          </Box>
        </Box>
      </Box>
      {/* <Play /> */}
    </>
  );
};

export default GamePage;
