import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Icon,
  Input,
  Stack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Circle,
  Center,
  Spinner
} from '@chakra-ui/react';
import { AiOutlineCheck, AiOutlineSearch } from 'react-icons/ai';
import { BiX } from 'react-icons/bi';
import uniq from 'lodash/uniq';
import { Media, responsive } from 'contexts/responsive';
import OpenLabHeader from 'containers/OpenLabHeader';
import SVG from 'components/SVG';
import Button from 'components/Button';
import RatioImage from 'components/RatioImage';
import Footer from 'components/footer/Home';
import useSWR from 'swr';

import Contact from '../Contact';
import title_icon from './title_icon.png';
import bg from './bg.svg';
import bg_m from './bg_m.svg';
import no_results from './no_results.svg';
import fetcher from 'helpers/fetcher';
import Seo from 'components/Seo';

const types = [
  '方案內容',
  '使用操作',
  '進階功能內容',
  '進階功能操作',
  '資料儲存',
  '其他',
  '心理測驗',
  '多維度測驗',
  '問卷一頁一題',
  '問卷一頁多題'
  // '得分挑戰',
  // '客製圖產生器',
];

const Faq = () => {
  const [search, setSearch] = useState();
  const { data = [] } = useSWR(`/api/faq`, fetcher);
  const [selectTypes, setTypes] = useState([]);

  const fieldData = useMemo(() => {
    try {
      return data.map((d) => ({ ...d, types: d.types.split('、') }));
    } catch (e) {
      console.error(e);
      return [];
    }
  }, [data]);
  // const types = useMemo(() => {
  //   const categories = fieldData.reduce((res, d) => {
  //     res = res.concat(d.types)
  //     return res
  //   }, [])
  //   return uniq(categories)
  // }, [fieldData])
  const filterData = useMemo(
    () =>
      selectTypes.length > 0
        ? fieldData.filter(
            (d) =>
              uniq(d.types.concat(selectTypes)).length <
              d.types.length + selectTypes.length
          )
        : fieldData,
    [fieldData, selectTypes]
  );

  const searchData = useMemo(
    () =>
      search
        ? filterData.filter((d) => d.question.includes(search))
        : filterData,
    [search, filterData]
  );
  // console.log(searchData)
  return (
    <>
      <Seo data={{ title: '常見問題' }} />
      <Box pos="relative" bg="lightYellow">
        <OpenLabHeader />
        <Box
          as={Media}
          greaterThanOrEqual="md"
          pos="absolute"
          top="0"
          left="0"
          right="0"
        >
          <RatioImage src={bg} ratio={1280 / 934} />
        </Box>
        <Box
          as={Media}
          lessThan="md"
          pos="absolute"
          top="2em"
          left="0"
          right="0"
        >
          <RatioImage src={bg_m} ratio={375 / 533} />
        </Box>
        <Stack
          pos="relative"
          spacing={responsive(4, '26px')}
          mx="auto"
          maxWidth={responsive('auto', '42em')}
          px="1em"
          pt={responsive('6em', '8.25em')}
          pb={responsive('7em', '5.5em')}
        >
          <Flex gap={responsive(8, '11px')}>
            <Box flex={1}>
              <Heading fontSize={responsive('1.5em', '2.25em')}>
                在使用上，大家都問了這些問題
              </Heading>
              {/* <Text mt={responsive(2, 6)} fontWeight={500} fontSize={responsive('0.875em', '1.5em')} letterSpacing="1.8px">
              內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文
            </Text> */}
            </Box>
            <Box width={responsive('7.75rem', '204px')}>
              <RatioImage src={title_icon} ratio={408 / 336} />
            </Box>
          </Flex>
          <Box position={'relative'}>
            <Input
              px="3rem"
              placeholder="搜尋文字..."
              borderColor="black"
              borderRadius={'full'}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            <Box
              pos="absolute"
              left="12px"
              top="50%"
              transform={'translateY(-50%)'}
              lineHeight={'1'}
              pointerEvents={'none'}
            >
              <Icon as={AiOutlineSearch} w={'26px'} h={'26px'} />
            </Box>
            {search && (
              <Box
                pos="absolute"
                right="12px"
                top="50%"
                transform={'translateY(-50%)'}
                lineHeight={'1'}
                onClick={() => {
                  setSearch('');
                }}
                cursor={'pointer'}
                zIndex={5}
              >
                <Icon as={BiX} w={'28px'} h={'28px'} />
              </Box>
            )}
          </Box>
          <Box>
            <Flex
              align={'center'}
              justify={'space-between'}
              fontWeight={'bold'}
              borderBottom={'1px'}
              pb="6px"
            >
              <Text>依類別搜尋</Text>
              <Box
                cursor={'pointer'}
                fontSize={'0.75em'}
                textDecoration={'underline'}
                onClick={() => setTypes([])}
              >
                清除選取
              </Box>
            </Flex>
            <Flex gap={'12px'} flexWrap={'wrap'} pt="1em">
              {types.map((d, i) => (
                <Button
                  p="0.25em"
                  h="auto"
                  w="auto"
                  bg={selectTypes.includes(d) ? '#FFC23C' : '#EAE8E6'}
                  fontWeight="bold"
                  color="black"
                  minW="auto"
                  letterSpacing="1.6px"
                  borderRadius="0"
                  border="1px solid"
                  lineHeight="1.2"
                  fontSize={responsive('0.75em', '1em')}
                  _hover={{
                    bg: '#FFC23C'
                  }}
                  onClick={() => {
                    if (selectTypes.includes(d)) {
                      const newType = selectTypes.filter((type) => type !== d);
                      setTypes([...newType]);
                    } else {
                      selectTypes.push(d);
                      setTypes([...selectTypes]);
                    }
                  }}
                  key={i}
                >
                  {selectTypes.includes(d) && (
                    <Icon as={AiOutlineCheck} w={'20px'} h={'20px'} />
                  )}
                  {d}
                </Button>
              ))}
            </Flex>
          </Box>
          <Accordion mt={responsive('1em', '2em')} allowMultiple>
            <Stack spacing={responsive(4, null, 6)}>
              {data?.length ? (
                searchData.length ? (
                  searchData.map((d, i) => (
                    <AccordionItem bg="black" border="none" key={i}>
                      {({ isExpanded }) => (
                        <>
                          <AccordionButton
                            py={responsive('0.5rem', null, '0.875rem')}
                            px={responsive('0.625rem', '2rem')}
                            fontSize={responsive('0.875em', '1.5em')}
                            bg="black"
                            color="white"
                          >
                            <Box
                              flex="1"
                              textAlign="left"
                              mr={responsive('0.75rem', '1rem')}
                            >
                              {d.question}
                            </Box>
                            <Circle
                              size={responsive('1.25rem', '2.5rem')}
                              transform={
                                isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'
                              }
                              border="1px solid white"
                            >
                              <SVG
                                viewBox="0 0 50 50"
                                width={['1.25rem', '2.5rem']}
                                height={['1.25rem', '2.5rem']}
                                alt=""
                              >
                                <use xlinkHref="/sprites/home.svg#mediumArrow" />
                              </SVG>
                            </Circle>
                          </AccordionButton>
                          <AccordionPanel p="1px">
                            <Box
                              px={responsive('1rem', '1.875rem')}
                              pt={responsive('1.5rem', '2.375rem')}
                              pb={responsive('1.25rem', '2.375rem')}
                              fontSize={responsive('0.875em', '1.5em')}
                              borderBottomRadius={'1.25rem'}
                              bg="white"
                              whiteSpace={'pre-wrap'}
                            >
                              {d.reply}
                            </Box>
                          </AccordionPanel>
                        </>
                      )}
                    </AccordionItem>
                  ))
                ) : (
                  <Box textAlign={'center'} mt={responsive('3em', '3.75em')}>
                    <Box mx="auto" width={responsive('11.25em', '12.5em')}>
                      <RatioImage src={no_results} ratio={200 / 195} />
                    </Box>
                    <Text
                      mt="1.75rem"
                      fontWeight={'bold'}
                      fontSize={responsive('1em', '1.5em')}
                    >
                      你選取的類別沒有搜尋到任何相關問題。
                    </Text>
                    <Text
                      fontWeight={500}
                      fontSize={responsive('0.875em', '1em')}
                    >
                      試試看刪除類別或用其他關鍵字吧！
                      <Box
                        ml="0.5rem"
                        display={'inline-block'}
                        cursor={'pointer'}
                        fontWeight={'bold'}
                        textDecoration={'underline'}
                        onClick={() => setTypes([])}
                      >
                        清除類別
                      </Box>
                    </Text>
                  </Box>
                )
              ) : (
                <Center>
                  <Spinner />
                </Center>
              )}
            </Stack>
          </Accordion>
        </Stack>
        <Contact />
        <Footer />
      </Box>
    </>
  );
};

export default Faq;
