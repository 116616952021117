import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { Media } from 'contexts/responsive';
import RatioImage from 'components/RatioImage';
import motivation from './images/motivation.png';
import motivation_mobile from './images/motivation_mobile.png';

const Motivation = () => {
  return (
    <Box pt={['3.75em', null, null, '3.125em']} px="1em" textAlign={'center'}>
      <Media greaterThanOrEqual="md">
        <Text fontSize="2.5em" fontWeight={'black'}>
          超開放實驗室的核心精神與目標
        </Text>
        <Box width="72.8%" mt="3.25em" mx="auto">
          <RatioImage
            src={motivation}
            ratio={945 / 426}
            alt="讓單向的溝通體驗，變成有共鳴的好玩測驗；提高自然流量 & 轉換率，降低對廣告的依賴；RE:LAB 在過去十年為各產業品牌客製超過 60 種遊戲化測驗；模組都經過多次實測，實戰中不斷調整優化"
          />
        </Box>
      </Media>
      <Media lessThan="md">
        <Text textAlign={'center'} fontSize="1.75em" fontWeight={900}>
          超開放實驗室的<br />核心精神與目標
        </Text>
        <Box
          width="100%"
          maxW={['min(100%, 47.5vh)', 'min(30em, 50vh)']}
          mt="0.75em"
          mx="auto"
        >
          <RatioImage
            src={motivation_mobile}
            ratio={800 / 982}
            alt="讓單向的溝通體驗，變成有共鳴的好玩測驗；提高自然流量 & 轉換率，降低對廣告的依賴；RE:LAB 在過去十年為各產業品牌客製超過 60 種遊戲化測驗；模組都經過多次實測，實戰中不斷調整優化"
          />
        </Box>
      </Media>
    </Box>
  );
};

export default Motivation;
