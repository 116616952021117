import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Media } from '../contexts/responsive';
import { content, Module } from './Contact';

const CreativeMobile = () => {
  return (
    <Box>
      <Flex justifyContent="center" as={Media} lessThan="md">
        {content.map((d, i) => Boolean(i) && <Module d={d} key={i} />)}
      </Flex>
    </Box>
  );
};

export default CreativeMobile;
