import React from 'react';
import { Box, Heading, Center, Flex, HStack, Text } from '@chakra-ui/react';
import RatioImage from 'components/RatioImage';
import TitleText from 'components/TitleText';
import { Media, responsive } from 'contexts/responsive';
import compare_title from './compare.png';
import vs from './vs.png';
import crown from './crown.png';
import thumb from './thumb.png';
import open from '../images/open.png';
import advantage from '../images/advantage.png';

const comparsions = [
  { title: '客製遊戲化測驗', mobileTitle: '客製\n遊戲化測驗', content: ['1 個月以上', '10 萬以上', '合作對象\n決定心累程度'], bg: 'gray.200' },
  { title: '使用超開放模組', mobileTitle: '使用\n超開放模組', content: ['1-3 小時', '2 萬以下', '心還沒累\n就完成了'], bg: '#fff' },
];

const subTitles = ['製作時間', '程式預算']

const Compare = () => {
  return (
    <Box>
      <Flex justifyContent={'center'} alignItems={responsive('auto', 'center')}>
        <TitleText>製作成本</TitleText>
        <Box ml="0.75rem" width={responsive('8.5em', '13.75em')}>
          <RatioImage src={compare_title} alt="compare" ratio={447 / 134} />
        </Box>
      </Flex>
      <Box
        maxW={responsive('34em', '47em')}
        px={responsive('2em', '3em')}
        mx="auto"
      >
        {/* <Heading width={responsive('19.25em', '29.5em')} fontSize="unset">
          <RatioImage
            src={customize_title}
            ratio={943 / 171}
            alt="客製 vs 模組"
          />
        </Heading> */}
        <Flex
          width="100%"
          mt={responsive('2.5em', '4em')}
          pos="relative"
          gap={[2, 4, 0]}
          justifyContent={'space-between'}
        >
          {comparsions.map(({ title, mobileTitle, content, bg }, k) => (
            <Box pos="relative" bg="black" border="1px solid" key={title}>
              <Box as={Media} greaterThanOrEqual="md" letterSpacing={'0.027em'} mt={'0.25rem'} textAlign={'center'} color="white" fontSize={'2em'} px={'1.25rem'} fontWeight="700">{title}</Box>
              <Box as={Media} lessThan="md" whiteSpace={'pre-wrap'} letterSpacing={'0.027em'} mt={'0.25rem'} textAlign={'center'} color="white" fontSize={['5.86vw', '1.75em']} px={'4vw'} fontWeight="700">{mobileTitle}</Box>
              <Box borderRadius={'1rem'} mt={responsive('0.375em', '0.875em')} pb={responsive('0', '1.5em')} px={responsive('0.375em', '0.875rem')} bg={bg}>
                {content.map((text, index) => (
                  <Box pos="relative" textAlign={'center'} py={responsive('0.75em', '1.5em')} borderTop={index && '1px solid'} key={index}>
                    <Text fontWeight={500} fontSize={responsive('0.875rem', '1em')}>{subTitles[index]}</Text>
                    <Text fontWeight={900} mt="0.25rem" fontSize={responsive('1em', '1.5em')} whiteSpace="pre-wrap">{text}</Text>
                    {!!k && (
                      <Box
                        pos="absolute"
                        top={'50%'}
                        right={['5%', null, '0%']}
                        width={responsive('2em', '3.625em')}
                        transform={responsive("translate(0.25em, -50%)", "translate(-0.5em, -50%)")}
                      >
                        <RatioImage src={thumb} ratio={174 / 168} alt="thumb" />
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
              {!!k && (
                <Box
                  pos="absolute"
                  top={'0%'}
                  left={responsive('55%', '52.5%')}
                  width={responsive('3.125em', '5.125rem')}
                  transform="translate(-50%, -100%)"
                >
                  <RatioImage src={crown} ratio={247 / 193} alt="crown" />
                </Box>
              )}
            </Box>
          ))}
          <Box
            pos="absolute"
            top={'50%'}
            left={'50%'}
            width={['4.625em', '7.5em', '9.75em']}
            transform="translate(-50%, -50%)"
          >
            <RatioImage src={vs} ratio={465 / 240} alt="vs" />
          </Box>
        </Flex>
        {/* <Box width="100%">
          {compare.map((column, index) => (
            <Flex
              justifyContent="space-between"
              alignItems="center"
              py="1.5em"
              fontWeight={700}
              borderTop={index && '1px solid black'}
              key={index}
            >
              <Box
                width={responsive('22%', 1 / 3)}
                fontSize={responsive('1em', '1.375em')}
                fontWeight={900}
              >
                {column.label}
              </Box>
              <Box
                width={responsive('39%', 1 / 3)}
                mr={responsive(null, '2.375rem')}
                fontSize={responsive('13px', '1.125em')}
                textAlign="center"
              >
                {column.custom}
              </Box>
              <Flex
                alignItems="center"
                justifyContent="center"
                pos="relative"
                width={responsive('39%', 1 / 3)}
              >
                <Text
                  width={responsive(
                    `${
                      column.module.length > 6
                        ? round(column.module.length / 2)
                        : column.module.length
                    }em`,
                    'auto'
                  )}
                  fontSize={responsive('13px', '1.125em')}
                  textAlign="center"
                >
                  {column.module}
                </Text>
                <Box
                  pos={responsive('absolute', 'static')}
                  width={responsive('1.25em', '2.25em')}
                  top="50%"
                  right={index > 1 ? '6px' : '10px'}
                  ml={responsive('0.5em', '1em')}
                  transform={'translateY(-50%)'}
                >
                  <RatioImage src={advantage} ratio={1} alt="" />
                </Box>
              </Flex>
            </Flex>
          ))}
        </Box> */}
      </Box>
    </Box>
  );
};

export default Compare;
