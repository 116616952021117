import { useState, useRef, useEffect } from 'react';
import {
  Heading,
  List,
  ListItem,
  Text,
  Box,
  Flex,
  Icon,
  Center,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { FEE_MONTHLY, FEE_MODULE } from 'constants/fee';
import getNumberComma from 'helpers/getNumberComma';
import SVG from 'components/SVG';
import Button from 'components/Button';
import { FaCheck } from 'react-icons/fa';
import { Media, responsive } from 'contexts/responsive';
import TitleText from 'components/TitleText';
import RatioImage from 'components/RatioImage';
import free_survey from './images/free_survey.png';
import module_discount from './images/module_discount.png';
import Carousel from 'components/Carousel';
import Link from 'components/Link';
import AddCase from './AddCase';

const FeatureItem = ({ icon, children, ...props }) => {
  return (
    <ListItem fontSize="md" lineHeight={1} {...props}>
      {icon}
      {children}
    </ListItem>
  );
};

const FeatureItemOffer = ({ index, plans }) => {
  if (!plans[index].offer) return;

  return (
    <FeatureItem
      icon={
        <Icon viewBox="0 0 24 24" me={2} verticalAlign="bottom">
          <use xlinkHref="/sprites/home.svg#redStar" />
        </Icon>
      }
      fontWeight="bold"
    >
      {plans[index].offer}
    </FeatureItem>
  );
};

const CaseBlock = ({
  plans,
  messages,
  align,
  index,
  description,
  fee,
  features,
  name,
  title,
  href,
  trackerName,
  ...props
}) => (
  <Flex flexDir={'column'} width="100%" {...props}>
    <Text
      height={[10, 12, 16]}
      textAlign={'center'}
      fontSize={['xl', '2xl', '3xl']}
      fontWeight="bold"
    >
      {title}
    </Text>
    <Box
      position="relative"
      paddingBlock={4}
      backgroundColor={'black'}
      css={{
        scrollSnapAlign: align
      }}
      _after={{
        display: 'block',
        content: '""',
        position: 'absolute',
        inset: '1px',
        backgroundColor: 'white',
        borderRadius: 'lg'
      }}
      transform="tranlate3d(0)"
      flex={1}
    >
      {index ? (
        <Box height="100%">
          <Text
            position="relative"
            zIndex={1}
            mb={3}
            fontWeight={500}
            textAlign="center"
          >
            {description}
          </Text>
          <Box position="relative" zIndex={1} mb={4} align="center">
            <Box mb={4}>
              <Text
                as="strong"
                fontSize="3xl"
                dangerouslySetInnerHTML={{ __html: fee?.number }}
              />
              <Text as="strong" fontSize="md">
                {fee?.affix}
              </Text>
              <Text>(未稅)</Text>
            </Box>
            <Button
              variant="outline"
              fontSize="lg"
              px={16}
              _hover={{
                color: 'white',
                backgroundColor: 'black',
                borderColor: 'black'
              }}
              trackerName={trackerName}
              trackerType="plan_click"
              href={href}
            >
              {messages.cta}
              <SVG w={5} h={5} ms={1} viewBox="0 0 50 50" alt="">
                <use xlinkHref="/sprites/home.svg#mediumArrow" />
              </SVG>
            </Button>
          </Box>
          <Center>
            <List
              spacing={[4, 5]}
              position="relative"
              zIndex={1}
              mb={2}
              // paddingInlineStart={paddingInlineStart}
            >
              {features?.map((item, index) => (
                <FeatureItem
                  icon={<Icon as={FaCheck} me={2} verticalAlign="bottom" />}
                  fontWeight={500}
                  key={`plan-${name}-${index}`}
                >
                  {item}
                </FeatureItem>
              ))}
              <FeatureItemOffer plans={plans} index={index} />
            </List>
          </Center>
        </Box>
      ) : (
        <Stack
          position="relative"
          height="100%"
          justifyContent={'center'}
          zIndex={3}
          spacing={2}
        >
          <Button
            h="auto"
            trackerName={`plan_free`}
            trackerType="plan_click"
            variant="unstyled"
            href="/editor"
          >
            <RatioImage src={free_survey} ratio={900 / 402} alt="free" />
          </Button>
          <Button
            h="auto"
            trackerName={`plan_campaign`}
            trackerType="plan_click"
            variant="unstyled"
            href="/plans?plan=campaign"
          >
            <RatioImage
              src={module_discount}
              ratio={900 / 510}
              alt="discount"
            />
          </Button>
        </Stack>
      )}
    </Box>
  </Flex>
);

const Plans = () => {
  const messages = {
    // number: 2,
    title: '付費方案',
    alt: '自由選',
    cta: '付費解鎖'
  };
  const plans = [
    {
      name: 'discount',
      title: '驚喜特惠',
      accentColor: '#FDC65A'
    },
    {
      name: 'monthly',
      title: '月費計價',
      description: '適合活動檔期短且規劃明確的團隊',
      href: '/plans?plan=monthly',
      fee: {
        number: `$${getNumberComma(FEE_MONTHLY, true)}`,
        affix: ' /月'
      },
      paddingInlineStart: [14, 16],
      features: [
        <>
          可使用平台上<strong>所有模組</strong>
        </>,
        <>
          可製作遊戲數量<strong>無限制</strong>
        </>,
        <>
          可發布遊戲數量<strong>無限制</strong>
        </>,
        <>
          每月填答量 <strong>1 萬筆</strong>
        </>
      ],
      offer: '買 10 個月，加贈 1 個月',
      accentColor: '#52D5AE',
      align: 'start'
    },
    {
      name: 'byModule',
      title: '模組計價',
      fee: {
        number: `$${getNumberComma(FEE_MODULE[0].from, true)}-${getNumberComma(
          FEE_MODULE[0].to,
          true
        )}`,
        affix: ' /個'
      },
      description: '適合想要長期使用特定模組的團隊',
      href: '/plans?plan=byModule',
      paddingInlineStart: [8, 10],
      features: [
        <>
          可長達 <strong>6 個月</strong>使用選購的模組
        </>,
        <>
          該模組可製作遊戲數量<strong>無限制</strong>
        </>,
        <>
          該模組可發布遊戲數量<strong>無限制</strong>
        </>,
        <>
          該模組填答量 <strong>3 萬筆</strong>
        </>
      ],
      offer: '進階功能提供 9 折優惠',
      accentColor: '#FF6026',
      align: 'end'
    }
  ];

  const [activePlan, setActivePlan] = useState(0);
  const listsRef = useRef(null);
  const { onOpen, onClose, isOpen } = useDisclosure()
  // console.log(listsRef?.current.scrollWidth)
  const handlePlansToggle = (plan) => {
    if (plan === activePlan) return;

    setActivePlan(plan);

    return listsRef.current.scrollTo({
      top: 0,
      left:
        plan === 0
          ? 0
          : plan === 1
          ? listsRef.current.scrollWidth / 3
          : listsRef.current.scrollWidth,
      behavior: 'smooth'
    });
  };

  return (
    <Box>
      <Flex
        alignItems="flex-end"
        justifyContent={'center'}
        mb={4}
        letterSpacing="0.027em"
      >
        <TitleText>
          {/* <TitleText.Num>{messages.number} </TitleText.Num> */}
          {messages.title}
        </TitleText>
        <SVG
          mb="0.5rem"
          viewBox="0 0 242 66"
          width="100%"
          maxWidth={responsive('8rem', '18.75rem')}
        >
          <title>自由選</title>
          <use xlinkHref="/sprites/home.svg#textAtWill" />
        </SVG>
      </Flex>
      <Box
        as={Media}
        greaterThanOrEqual="md"
        mx="auto"
        maxWidth={'66.5em'}
        px="1em"
      >
        {/* <Flex
          as="ul"
          gap={[2, null, 12]}
          width={[null, null, 'full']}
          mx={[9, null, 'auto']}
        >
          {plans.map(({ name, title }, index) => (
            <Box
              as="li"
              flex="1 1 50%"
              fontSize={0}
              textAlign="center"
              listStyleType="none"
              borderColor={['black', null, 'transparent']}
              borderWidth="1px 1px 0 1px"
              key={`plan-title-${name}`}
            >
              <Button
                type="button"
                variant="unstyled"
                onClick={() => handlePlansToggle(index)}
                width="100%"
                height={[10, 10, 16]}
                fontSize={['xl', 'xl', '3xl']}
                fontWeight="bold"
                backgroundColor={[
                  index === activePlan
                    ? plans[activePlan].accentColor
                    : 'gray.200', null,
                  'transparent'
                ]}
                borderRadius="none"
                // borderBottomColor="transparent"
                pointerEvents={[null, null, 'none']}
              >
                {title}
              </Button>
            </Box>
          ))}
        </Flex> */}
        <Flex ref={listsRef} gap={12} pos="relative">
          {plans.map(
            (
              { name, title, description, fee, features, href, align },
              index
            ) => (
              <CaseBlock
                flex={1}
                fee={fee}
                name={name}
                title={title}
                index={index}
                align={align}
                plans={plans}
                features={features}
                messages={messages}
                href={href}
                trackerName={`plan_${name}`}
                description={description}
                key={`plan-${name}`}
              />
            )
          )}
        </Flex>
      </Box>
      <Box as={Media} lessThan="md" maxWidth={'32em'} mx="auto" px="1em">
        <Carousel
          isPlan
          dotProps={{
            transform: 'translate(-50%, 150%)'
          }}
          // sliderProps={{
          //   height: '100%'
          // }}
        >
          {plans.map(
            (
              {
                name,
                description,
                fee,
                features,
                title,
                href,
                trackerName,
                align
              },
              index
            ) => (
              <CaseBlock
                px={['1.5em', null, 0]}
                height="100%"
                fee={fee}
                name={name}
                href={href}
                index={index}
                align={align}
                plans={plans}
                title={title}
                features={features}
                messages={messages}
                trackerName={`plan_${name}`}
                description={description}
                display="flex!important"
                key={`plan-${name}`}
              />
            )
          )}
        </Carousel>
      </Box>
      <Flex justifyContent={'center'} align={'center'} mt={responsive('3.5em', '1.5em')}>
        <Text fontWeight={500}>需要更多填答筆數嗎？</Text>
        <Text fontWeight={'bold'} cursor={'pointer'} onClick={onOpen} textDecor={'underline'}>點我查看加購方案</Text>
      </Flex>
      <AddCase isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default Plans;
