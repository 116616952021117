import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import RatioImage from 'components/RatioImage';
import price from './price.png';
import { responsive } from 'contexts/responsive';
import Link from 'next/link';

const AddCase = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent width={responsive('100%', '496px')} maxW="auto" p="0" bg="transparent" boxShadow={'none'}>
        <ModalCloseButton
          right={responsive('1.5rem', '2rem')}
          top={responsive('3.125rem', '4rem')}
          w="2.5rem"
          h="2.5rem"
          border="1px solid"
          fontSize={'1.125em'}
          borderRadius={0}
          bg="white"
          zIndex={99}
        />
        <Link href="/plans/repurchase">
          <RatioImage src={price} ratio={1120 / 1270} alt="價目表" />
        </Link>
      </ModalContent>
    </Modal>
  )
}

export default AddCase
