import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import RatioImage from 'components/RatioImage';
import { Media, responsive } from '../../contexts/responsive';
import click from './pic_click.png';
import heart from './pic_heart.png';
import money from './pic_money.png';
import deco from './deco.png';
import TitleText from 'components/TitleText';

const content = [
  {
    icon: money,
    alt: '$符號Icon',
    title: '更低的流量成本',
    text: (
      <>
        品牌跨平台經營不易，
        <br />
        協助用更低成本獲取<b>高互動</b>
      </>
    )
  },
  {
    icon: click,
    alt: '滑鼠游標Icon',
    title: '創造更高的轉換',
    text: (
      <>
        透過內建遊戲化機制，
        <br />
        提高互動體驗與<b>轉換意願</b>
      </>
    )
  },
  {
    icon: heart,
    alt: '愛心Icon',
    title: '維繫品牌忠誠度',
    text: (
      <>
        透過社群心理需求，
        <br />
        拉近粉絲與<b>品牌的關係</b>
      </>
    )
  }
];

const Reasons = () => {
  return (
    <Box
      px={['2em', '10%', '13.25em']}
      pt={['3.75em', null, null, '6.25em']}
      pos="relative"
      zIndex={5}
    >
      <Box
        width={responsive('auto', '41.2%')}
        left={responsive('-11.5%', 'unset')}
        right={responsive('-11.5%', '-10%')}
        bottom={responsive('-2.5%', '-20%')}
        pos="absolute"
      >
        <RatioImage src={deco} ratio={530 / 397} alt="背景裝飾" />
      </Box>
      <Box pos="relative">
        <Media greaterThanOrEqual={'md'}>
          <TitleText textAlign="center">
            <TitleText.Num>3 </TitleText.Num>
            個不能錯過超開放實驗室的原因
          </TitleText>
        </Media>
        <Media lessThan={'md'}>
          <Text fontWeight={'black'} textAlign={'center'} fontSize="1.75em">
            <Text as="span" fontSize="2.75rem">
              3{' '}
            </Text>
            個不能錯過
            <br />
            超開放實驗室的原因
          </Text>
        </Media>
      </Box>
      <Flex pos="relative" flexDir={responsive('column', 'row')}>
        {content.map(({ icon, title, text, alt }) => (
          <Flex
            mt={responsive('1.25em', '2.25em')}
            alignItems={'center'}
            width={responsive('100%', 1 / 3)}
            textAlign={responsive('left', 'center')}
            flexDir={responsive('row', 'column')}
            key={title}
          >
            <Box
              mb={responsive(0, '2em')}
              mx="auto"
              width={responsive('30%', '7.5em')}
            >
              <RatioImage src={icon} ratio={1} alt={alt} />
            </Box>
            <Box ml={responsive('1em', 0)} flex={1}>
              <Text fontWeight={700} fontSize={responsive('1.25em', '1.875vw')}>
                {title}
              </Text>
              <Text
                whiteSpace={'pre-wrap'}
                fontSize={responsive('1em', '1.25vw')}
              >
                {text}
              </Text>
            </Box>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

export default Reasons;
