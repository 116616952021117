import { Stack } from '@chakra-ui/react'
import { responsive } from 'contexts/responsive'
import React from 'react'
import Compare from './Compare'
import Plans from './Plans'
import FeeAdvanced from './FeeAdvanced'

const FeeSection = () => {
  return (
    <Stack
      py={responsive('3.75em', '6.25em')}
      spacing={['100px', '150px']}
      pos={'relative'}
      bg={'inherit'}
      zIndex={6}
    >
      <Plans />
      <FeeAdvanced />
      <Compare />
    </Stack>
  )
}

export default FeeSection
