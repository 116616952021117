import React from 'react';
import { Box, Center, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { Media, responsive } from 'contexts/responsive';
import RatioImage from 'components/RatioImage';
import website from './images/website.png';
import dialog from './images/dialog.png';
import lab_bg from './images/lab_bg.png';
import lab_mobile from './images/lab_mobile.png';
import pic_feature from './images/pic_feature.png';
import pic_feature_m from './images/pic_feature-m.png';
import TitleText from 'components/TitleText';

export const TextBgModule = ({ children, ...props }) => (
  <Box
    display="inline-block"
    pos="relative"
    py={['0.375rem', 2]}
    pr={[1, 3]}
    pl={['0.375rem', 4]}
    color="white"
    fontSize={['0.875em', '1em', '1.25em', '1.5em']}
    lineHeight={1.25}
    letterSpacing="2.7px"
    bg="black"
    {...props}
  >
    {children}
  </Box>
);

// const descriptions = [
//   {
//     text: '可一鍵產出多個網址\n讓數據追蹤更便利',
//     src: website
//   },
//   {
//     text: '可在多個平台發布\n透過導流放大話題',
//     src: dialog
//   }
// ];

const alt = '更低的開發成本、不用程式基礎、體驗與視覺吸睛'

const Lab = () => {
  return (
    <Box pos="relative" pt={['6.625em', null, '10.25em']} pb={['4em', null, '6.875em']}>
      <Box
        width={'100%'}
        pos="absolute"
        top={responsive('50%', '0')}
        right={responsive(null, '0')}
        bottom={responsive(null, '0')}
        left={responsive('50%', '0')}
        transform={responsive('translate(-50%, -50%)', 'none')}
      >
        <Media greaterThanOrEqual="md">
          <RatioImage src={lab_bg} ratio={1920 / 1080} alt="OPEN" />
        </Media>
        <Media lessThan="md">
          <RatioImage src={lab_mobile} ratio={1125 / 1281} alt="OPEN" />
        </Media>
      </Box>
      <Box
        flexDir="column"
        width="100%"
        maxWidth={responsive('90%', '70%')}
        pos="relative"
        zIndex={5}
        mx="auto"
        transform="translate3d(0,0,0)"
      >
        <TitleText whiteSpace={responsive('pre-wrap', 'nowrap')}>
          {'用了就會愛上的\n'}
          <TitleText.Num>3</TitleText.Num>
          {' 大特點'}
        </TitleText>
        <Box mt={responsive('0', '3.25em')}>
          <Media greaterThanOrEqual="md">
            <RatioImage src={pic_feature} ratio={1908 / 600} alt={alt} />
          </Media>
          <Media lessThan="md">
            <RatioImage src={pic_feature_m} ratio={768 / 751} alt={alt} />
          </Media>
        </Box>
        {/* <Flex
          flexDirection={responsive('column', 'row')}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          {descriptions.map(({ text, src }, i) => (
            <Box
              key={i}
              pos="relative"
              mt={responsive('2em', '4.5em')}
              pt={responsive('2.25rem', '3em')}
              pr={responsive('3em', '5.625em')}
            >
              <Box
                width={responsive('6.25em', '11.375em')}
                pos="absolute"
                top="0"
                right="0"
              >
                <RatioImage src={src} ratio={367 / 287} alt="" />
              </Box>
              <Heading
                as="h3"
                pos="relative"
                fontSize={responsive('1.125em', '1.875em')}
                fontWeight={500}
                lineHeight={1.75}
                letterSpacing="1.8px"
                whiteSpace="pre-wrap"
                transform={'translate3d(0,0,0)'}
              >
                {text}
              </Heading>
            </Box>
          ))}
        </Flex> */}
      </Box>
    </Box>
  );
};

export default Lab;
