import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Media,responsive } from '../contexts/responsive';
import TitleText from 'components/TitleText';

const Communication = () => {
  return (
    <Box
      px={[0, '10%', '13.25em']}
      pt={['2.25em', null, null, '4em']}
      overflow="hidden"
    >
      <Media greaterThanOrEqual="md">
        <TitleText mb="0.75em" textAlign={'center'}>為什麼要讓品牌溝通更好玩？</TitleText>
      </Media>
      <Media lessThan="md">
        <TitleText
          mb="0.25rem"
          textAlign={'center'}
        >為什麼要讓<br />品牌溝通更好玩？
        </TitleText>
      </Media>
      <Media greaterThanOrEqual="md">
        <Box width="100%" maxW="85em" mx="auto">
          <svg viewBox="0 0 1464.11 835.87">
            <use
              xlinkHref="/sprites/home.svg#communicationFigureWide"
              alt="好的溝通是找到彼此都在意的事；持續的溝通是找到彼此都認為好玩的事"
            />
          </svg>
        </Box>
      </Media>
      <Media lessThan="md">
        <Box
          width="100%"
          maxW={['30em', 'auto']}
          pos="relative"
          left="50%"
          transform="translateX(-50%)"
        >
          <svg viewBox="0 0 375 450">
            <use
              xlinkHref="/sprites/home.svg#communicationFigure"
              alt="好的溝通是找到彼此都在意的事；持續的溝通是找到彼此都認為好玩的事"
            />
          </svg>
        </Box>
      </Media>
    </Box>
  );
};

export default Communication;
